import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeLgAlt,
  faCloud,
  faShieldAlt,
  faExclamationTriangle,
  faUsersCog,
  faStar,
  faBars,
  faCog,
  faDoorOpen,
  faIdCard,
  faExternalLink,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { getCustomer } from '../../graphql/queries';

import { useUser } from '../../context/UserContext';
import { customerGroups, effectualGroups, featureFlagGroups } from '../../shared/groupsHelper';
import { getBaseUrl } from '../../shared/helper';
import NavItem from './NavItem';

type Props = {
  drawer?: boolean;
  slidingDrawer?: (...args: any[]) => any;
  mobileNav?: (...args: any[]) => any;
  showMobileNav?: boolean;
};

const Nav = ({ slidingDrawer, drawer, mobileNav, showMobileNav }: Props) => {
  const location = useLocation();
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  // @ts-expect-error TS(2339): Property 'customerHasFeature' does not exist on type 'unknown'.
  const { userInRoles, isEffectualUser, customerHasFeature } = useUser();
  const [effectualNavItemList, setEffectualNavItemList] = useState([]);
  const [navItems, setNavItems] = useState([]);
  const [menuNavItemsList, setMenuNavItemlist] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl = getBaseUrl(customerId);
  const useMountEffect = (func: any) => useEffect(func, []);

  const initializeComponent = () => {
    (async () => {
      setIsLoading(true);
      try {
        if (isEffectualUser && customerId) {
          const res = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
          const name = (res as any)?.data?.getCustomer?.name ? (res as any).data.getCustomer.name : '';
          setCustomerName(name);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    })();
  };

  useMountEffect(initializeComponent);

  const buildEffectualMenu = useCallback(() => {
    const effectualNavItems = [];

    effectualNavItems.push(
      <NavItem
        key="home"
        active={location.pathname === '/empath'}
        href="/empath"
        label="Home"
        icon={<FontAwesomeIcon icon={faHomeLgAlt} fixedWidth />}
        drawer={drawer}
        mobileNav={mobileNav}
      />
    );

    effectualNavItems.push(
      <NavItem
        key="customers"
        active={location.pathname.indexOf('empath/customers') !== -1}
        href="/empath/customers"
        label="Customers"
        icon={<FontAwesomeIcon icon={faIdCard} fixedWidth />}
        drawer={drawer}
        mobileNav={mobileNav}
      />
    );

    if (userInRoles([effectualGroups.effectualAdmin.groupName])) {
      effectualNavItems.push(
        <NavItem
          key="effectualAdmin"
          active={location.pathname.indexOf('empath/users') !== -1}
          href="/empath/users"
          label="Empath Admin"
          icon={<FontAwesomeIcon icon={faUsersCog} fixedWidth />}
          drawer={drawer}
          mobileNav={mobileNav}
        />
      );
    }

    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setEffectualNavItemList(effectualNavItems);
  }, [drawer, location.pathname, mobileNav, userInRoles]);

  const buildCustomerMenu = useCallback(() => {
    const navItemList = [];

    if (isEffectualUser) {
      navItemList.push(
        <div key="effectual-hr">
          <hr className="w-4/5 h-px mx-auto mb-6 border-none bg-orange" />
        </div>
      );
    }

    navItemList.push(
      <NavItem
        key="home"
        active={location.pathname === baseUrl}
        href={`${baseUrl}`}
        label={`${isEffectualUser ? `${customerName} ` : ''}Home`}
        icon={<FontAwesomeIcon icon={faHomeLgAlt} fixedWidth />}
        drawer={drawer}
        mobileNav={mobileNav}
      />
    );

    if (
      (userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualObserver.groupName,
        customerGroups.customerAdmin.groupName,
        customerGroups.customerSupport.groupName,
        customerGroups.customerObserver.groupName,
        customerGroups.customerTechnical.groupName,
      ]) &&
        customerHasFeature([featureFlagGroups.mcm.name])) ||
      (userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualObserver.groupName,
        customerGroups.customerAdmin.groupName,
        customerGroups.customerSupport.groupName,
        customerGroups.customerObserver.groupName,
        customerGroups.customerTechnical.groupName,
      ]) &&
        customerHasFeature([featureFlagGroups.vmc.name]))
    ) {
      navItemList.push(
        <NavItem
          key="mycloud"
          active={location.pathname.startsWith(`${baseUrl}/mycloud`)}
          href={`${baseUrl}/mycloud`}
          label="My Cloud"
          icon={<FontAwesomeIcon icon={faCloud} fixedWidth />}
          drawer={drawer}
          mobileNav={mobileNav}
        />
      );
    }
    if (
      (userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualObserver.groupName,
        customerGroups.customerAdmin.groupName,
        customerGroups.customerSupport.groupName,
        customerGroups.customerObserver.groupName,
        customerGroups.customerTechnical.groupName,
      ]) &&
        customerHasFeature([featureFlagGroups.mcm.name])) ||
      (userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualObserver.groupName,
        customerGroups.customerAdmin.groupName,
        customerGroups.customerSupport.groupName,
        customerGroups.customerObserver.groupName,
        customerGroups.customerTechnical.groupName,
      ]) &&
        customerHasFeature([featureFlagGroups.vmc.name]))
    ) {
      navItemList.push(
        <NavItem
          key="security"
          active={location.pathname.startsWith(`${baseUrl}/security`)}
          href={`${baseUrl}/security`}
          label="Security"
          icon={<FontAwesomeIcon icon={faShieldAlt} fixedWidth />}
          drawer={drawer}
          mobileNav={mobileNav}
        />
      );
    }

    if (
      userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualAccounting.groupName,
        effectualGroups.effectualObserver.groupName,
      ]) ||
      (!customerHasFeature([featureFlagGroups.resaleOnly.name]) &&
        userInRoles([
          customerGroups.customerAdmin.groupName,
          customerGroups.customerSupport.groupName,
          customerGroups.customerAccounting.groupName,
          customerGroups.customerObserver.groupName,
          customerGroups.customerTechnical.groupName,
          customerGroups.customerHelpDesk.groupName,
        ]))
    ) {
      navItemList.push(
        <NavItem
          key="support"
          active={location.pathname.startsWith(`${baseUrl}/support`)}
          href={`${baseUrl}/support/cases`}
          label="Support"
          icon={<FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />}
          drawer={drawer}
          mobileNav={mobileNav}
        />
      );
    }

    if (
      userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualAccounting.groupName,
        effectualGroups.effectualObserver.groupName,
        customerGroups.customerAccounting.groupName,
        customerGroups.customerAdmin.groupName,
        customerGroups.customerObserver.groupName,
        customerGroups.customerSupport.groupName,
        customerGroups.customerTechnical.groupName,
      ])
    ) {
      navItemList.push(
        <NavItem
          key="myteam"
          href={`${baseUrl}/my-team`}
          label="My Team"
          mobileNav={mobileNav}
          icon={<FontAwesomeIcon icon={faUsers} fixedWidth />}
          drawer={drawer}
          active={location.pathname.startsWith(`${baseUrl}/my-team`)}
        />
      );
    }

    if (
      userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualAccounting.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualObserver.groupName,
        customerGroups.customerAdmin.groupName,
      ])
    ) {
      navItemList.push(
        <NavItem
          key="admin"
          href={`${baseUrl}/admin/users`}
          label={`${isEffectualUser ? 'Customer ' : ''} Admin`}
          mobileNav={mobileNav}
          icon={<FontAwesomeIcon icon={faUsersCog} fixedWidth />}
          drawer={drawer}
          active={location.pathname.startsWith(`${baseUrl}/admin`)}
        />
      );
    }

    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setNavItems(navItemList);
  }, [isEffectualUser, location.pathname, baseUrl, drawer, mobileNav, userInRoles, customerHasFeature, customerName]);

  useEffect(() => {
    buildEffectualMenu();

    if (customerId) {
      buildCustomerMenu();
    }

    const menuNavItems = [];

    menuNavItems.push(
      <div key="effectual-hr">
        <hr className="w-4/5 h-px mx-auto mb-6 border-none bg-orange" />
      </div>
    );

    menuNavItems.push(
      <NavItem
        key="settings"
        href={`${baseUrl}/settings`}
        label="My Settings"
        mobileNav={mobileNav}
        icon={<FontAwesomeIcon icon={faCog} fixedWidth />}
        drawer={drawer}
      />
    );
    menuNavItems.push(
      <NavItem
        key="logout"
        href="/logout"
        label="Log Out"
        icon={<FontAwesomeIcon icon={faDoorOpen} fixedWidth />}
        drawer={drawer}
        external
      />
    );

    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setMenuNavItemlist(menuNavItems);
  }, [
    drawer,
    location,
    baseUrl,
    userInRoles,
    mobileNav,
    buildCustomerMenu,
    buildEffectualMenu,
    customerId,
    isEffectualUser,
  ]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="relative flex flex-col flex-1">
      <div className="flex">
        <button
          aria-label="Toggle Navigation Drawer"
          type="button"
          className="hidden pl-4 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-100 focus:ring-0 md:block"
          onClick={slidingDrawer}
          style={{ marginLeft: '2px' }}
        >
          <FontAwesomeIcon icon={faBars} fixedWidth />
        </button>
      </div>

      <div className="flex flex-col flex-1">
        {isEffectualUser && (
          <div className="mt-6 overflow-x-hidden overflow-y-auto">
            <nav className="space-y-1">{effectualNavItemList.map(navItem => navItem)}</nav>
          </div>
        )}

        {navItems.length > 0 && (
          <div className="mt-6 overflow-x-hidden overflow-y-auto">
            <nav className="space-y-1">{navItems.map(navItem => navItem)}</nav>
          </div>
        )}

        {showMobileNav && (
          <div className="mt-6 overflow-x-hidden overflow-y-auto">
            <nav className="space-y-1">{menuNavItemsList.map(navItem => navItem)}</nav>
          </div>
        )}

        <div className="flex flex-col justify-end flex-1 my-6 md:pb-0">
          <NavItem
            key="feedback"
            href="https://www.surveymonkey.com/r/GY72HWH"
            label={
              <p>
                Send Feedback
                <span className="ml-2">
                  <FontAwesomeIcon icon={faExternalLink} fixedWidth />
                </span>
              </p>
            }
            icon={<FontAwesomeIcon icon={faStar} fixedWidth />}
            drawer={drawer}
            active={false}
            newTab
            external
          />
        </div>
      </div>
    </div>
  );
};

export default Nav;
