/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $params: MutationUpdateTicketParamsInput!
    $query: MutationUpdateTicketQueryInput
    $body: MutationUpdateTicketBodyInput
  ) {
    updateTicket(params: $params, query: $query, body: $body) {
      id
      number
      category
      subject
      customerId
      state
      openedAt
      createdOn
      updatedOn
      data
      attachments
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $params: MutationCreateTicketParamsInput!
    $query: MutationCreateTicketQueryInput
    $body: MutationCreateTicketBodyInput
  ) {
    createTicket(params: $params, query: $query, body: $body) {
      id
      number
      category
      subject
      customerId
      state
      openedAt
      createdOn
      updatedOn
      data
      attachments
    }
  }
`;
export const createTicketComment = /* GraphQL */ `
  mutation CreateTicketComment(
    $params: MutationCreateTicketCommentParamsInput!
    $query: MutationCreateTicketCommentQueryInput
    $body: MutationCreateTicketCommentBodyInput
  ) {
    createTicketComment(params: $params, query: $query, body: $body) {
      items {
        id
        comment
        createdOn
        createdBy
      }
      attachments {
        id
        key
        fileName
        hash
      }
      token
    }
  }
`;
export const adminResetPassword = /* GraphQL */ `
  mutation AdminResetPassword($params: MutationAdminResetPasswordParamsInput!) {
    adminResetPassword(params: $params) {
      data
      code
      message
      name
    }
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser(
    $params: MutationUpdateCognitoUserParamsInput!
    $query: MutationUpdateCognitoUserQueryInput
    $body: MutationUpdateCognitoUserBodyInput
  ) {
    updateCognitoUser(params: $params, query: $query, body: $body) {
      Username
      UserAttributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
      }
      PreferredMfaSetting
      UserMFASettingList
      groups
      lastName
      firstName
      id
      email
      sub
    }
  }
`;
export const updateEmpathUser = /* GraphQL */ `
  mutation UpdateEmpathUser(
    $params: MutationUpdateEmpathUserParamsInput!
    $query: MutationUpdateEmpathUserQueryInput
    $body: MutationUpdateEmpathUserBodyInput
  ) {
    updateEmpathUser(params: $params, query: $query, body: $body) {
      id
      email
      sub
      customerId
      customerIds
      serviceNowId
      groups
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      onboarded
      canApproveChangeRequest
      dashboardSettings
    }
  }
`;
export const deleteEmpathUser = /* GraphQL */ `
  mutation DeleteEmpathUser(
    $params: MutationDeleteEmpathUserParamsInput!
    $body: MutationDeleteEmpathUserBodyInput
    ) {
    deleteEmpathUser(params: $params, body: $body) {
      data
      code
      message
      name
    }
  }
`;
export const addEmpathUser = /* GraphQL */ `
  mutation AddEmpathUser(
    $query: MutationAddEmpathUserQueryInput
    $body: MutationAddEmpathUserBodyInput
  ) {
    addEmpathUser(query: $query, body: $body) {
      id
      email
      sub
      customerId
      customerIds
      serviceNowId
      groups
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      onboarded
      canApproveChangeRequest
    }
  }
`;
export const sendTemplatedEmail = /* GraphQL */ `
  mutation SendTemplatedEmail(
    $query: MutationSendTemplatedEmailQueryInput
    $body: MutationSendTemplatedEmailBodyInput
  ) {
    sendTemplatedEmail(query: $query, body: $body) {
      data
      code
      message
      name
    }
  }
`;
export const setupSso = /* GraphQL */ `
  mutation SetupSso(
    $query: MutationSetupSsoQueryInput
    $body: MutationSetupSsoBodyInput
  ) {
    setupSso(query: $query, body: $body) {
      data
      code
      message
      name
    }
  }
`;
export const validateSso = /* GraphQL */ `
  mutation ValidateSso(
    $query: MutationValidateSsoQueryInput
    $body: MutationValidateSsoBodyInput
  ) {
    validateSso(query: $query, body: $body) {
      data
      code
      message
      name
    }
  }
`;
export const changeSso = /* GraphQL */ `
  mutation ChangeSso(
    $query: MutationChangeSsoQueryInput
    $body: MutationChangeSsoBodyInput
  ) {
    changeSso(query: $query, body: $body) {
      data
      code
      message
      name
    }
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser($params: MutationDeleteCognitoUserParamsInput!) {
    deleteCognitoUser(params: $params) {
      data
      code
      message
      name
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory(
    $input: CreateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    createInventory(input: $input, condition: $condition) {
      id
      customerId
      account
      region
      service
      resource
      workload
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory(
    $input: UpdateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    updateInventory(input: $input, condition: $condition) {
      id
      customerId
      account
      region
      service
      resource
      workload
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory(
    $input: DeleteInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    deleteInventory(input: $input, condition: $condition) {
      id
      customerId
      account
      region
      service
      resource
      workload
      data
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      isActive
      identityProvider
      metadataUrl
      ssoUrn
      ssoUrl
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      isActive
      identityProvider
      metadataUrl
      ssoUrn
      ssoUrl
      featureFlags {
        supportOnly
        resaleOnly
        mcm
        vmc
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      isActive
      identityProvider
      metadataUrl
      ssoUrn
      ssoUrl
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      onboarded
      sub
      customerId
      customerIds
      serviceNowId
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      groups
      identityId
      canApproveChangeRequest
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      onboarded
      sub
      customerId
      customerIds
      serviceNowId
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      groups
      identityId
      canApproveChangeRequest
      dashboardSettings
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      onboarded
      sub
      customerId
      customerIds
      serviceNowId
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      groups
      identityId
      canApproveChangeRequest
    }
  }
`;

export const addTeamMember = /* GraphQL */ `
  mutation AddTeamMember(
    $customerId: String!
    $body: MutationAddMyTeamMemberBodyInput
  ) {
    addTeamMember(customerId: $customerId, body: $body) {
      myTeam {
        includePhone
        isPrimary
        role
        teamMemberId
      }
      createdAt
      customerId
      updatedAt
    }
  }
`;

export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember(
    $customerId: String!
    $teamMemberId: String!
  ) {
    deleteTeamMember(customerId: $customerId, teamMemberId: $teamMemberId) {
      myTeam {
        includePhone
        isPrimary
        role
        teamMemberId
      }
      createdAt
      customerId
      updatedAt
    }
  }
`;


export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $customerId: String!
    $teamMemberId: String!
    $body: MutationUpdateMyTeamMemberBodyInput
  ) {
    updateTeamMember(customerId: $customerId, teamMemberId: $teamMemberId, body: $body) {
      myTeam {
        includePhone
        isPrimary
        role
        teamMemberId
      }
      createdAt
      customerId
      updatedAt
    }
  }
`;
