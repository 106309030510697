import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { useHistory, useParams } from 'react-router-dom';
import { updateUser } from '../../graphql/mutations';
import { useUser } from '../../context/UserContext';
import { getBaseUrl } from '../../shared/helper';
import { customerGroups, effectualGroups, featureFlagGroups } from '../../shared/groupsHelper';
import { EC2CPUCard, RDSMemoryCard, ResourceStatusMonitorCard } from '.';
import ScannedResourcesCard from './ScannedResourcesCard';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const getDashboardSettings = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      dashboardSettings
    }
  }
`;

const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const ROW_HEIGHT = viewWidth >= 2560 ? 40 : 30;
const BREAKPOINTS = { lg: 996, md: 480, sm: 0 };
const COLS = { lg: 3, md: 2, sm: 1 };
const DEFAUL_LAYOUT_CONSTANTS = { minW: 1, minH: 10, isResizable: false };
const DEFAULT_LAYOUT = {
  lg: [
    { x: 0, y: 0, w: 1, h: 10 },
    { x: 1, y: 0, w: 1, h: 10 },
    { x: 2, y: 0, w: 1, h: 10 },
    { x: 0, y: 10, w: 1, h: 10 },
    { x: 1, y: 10, w: 1, h: 10 },
    { x: 2, y: 10, w: 1, h: 10 },
  ],
  md: [
    { x: 0, y: 0, w: 1, h: 10 },
    { x: 1, y: 0, w: 1, h: 10 },
    { x: 0, y: 10, w: 1, h: 10 },
    { x: 1, y: 10, w: 1, h: 10 },
    { x: 0, y: 20, w: 1, h: 10 },
    { x: 1, y: 20, w: 1, h: 10 },
  ],
  sm: [
    { x: 0, y: 0, w: 1, h: 10 },
    { x: 0, y: 10, w: 1, h: 10 },
    { x: 0, y: 20, w: 1, h: 10 },
    { x: 0, y: 30, w: 1, h: 10 },
    { x: 0, y: 40, w: 1, h: 10 },
    { x: 0, y: 50, w: 1, h: 10 },
  ],
};

const DashboardLayout = () => {
  const [loading, setLoding] = useState(true);
  const [state, setState] = useState({});
  const { customerId } = useParams<{ customerId: string }>();
  const { activeUser, userInRoles, customerHasFeature } = useUser() as {
    activeUser: { id: string };
    userInRoles: (roles: string[]) => boolean;
    customerHasFeature: (features: string[]) => boolean;
  };
  const history = useHistory();
  const useMountEffect = (func: any) => useEffect(func, []);

  const getAllowedWidgetsCards = () => {
    let allowedWidgets: any = [];

    if (
      userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualObserver.groupName,
        customerGroups.customerAdmin.groupName,
        customerGroups.customerObserver.groupName,
        customerGroups.customerTechnical.groupName,
        customerGroups.customerSupport.groupName,
      ]) &&
      (userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualObserver.groupName,
      ]) ||
        !customerHasFeature([featureFlagGroups.resaleOnly.name]))
    ) {
      allowedWidgets = allowedWidgets.concat(['EC2CPUCard', 'RDSMemoryCard', 'ResourceStatusMonitorCard']);
    }

    return allowedWidgets;
  };

  const viewMetricsDetailsCallback = (showMonitorsTab: any) => {
    if (showMonitorsTab) {
      history.push({
        pathname: `${getBaseUrl(customerId)}/mycloud`,
        state: { detail: 'MonitorsTab' },
      });
    } else {
      history.push(`${getBaseUrl(customerId)}/mycloud`);
    }
  };

  const viewSecurityDetailsCallback = () => {
    history.push(`${getBaseUrl(customerId)}/security`);
  };

  const getCardWidget = (card: any) => {
    switch (card) {
      case 'EC2CPUCard':
        return (
          <EC2CPUCard
            customerId={customerId}
            detailLink={() => viewMetricsDetailsCallback(false)}
            classNames="w-full"
          />
        );
      case 'RDSMemoryCard':
        return (
          <RDSMemoryCard
            customerId={customerId}
            detailLink={() => viewMetricsDetailsCallback(false)}
            classNames="w-full"
          />
        );
      case 'ResourceStatusMonitorCard':
        return (
          <ResourceStatusMonitorCard
            customerId={customerId}
            viewDetailsCallback={() => viewMetricsDetailsCallback(true)}
            // @ts-expect-error TS(2322): Type '{ customerId: any; viewDetailsCallback: () =... Remove this comment to see the full error message
            classNames="w-full"
          />
        );
      case 'ScannedResources':
        return (
          <ScannedResourcesCard
            customerId={customerId}
            viewDetailsCallback={viewSecurityDetailsCallback}
            // @ts-expect-error TS(2322): Type '{ customerId: any; viewDetailsCallback: () =... Remove this comment to see the full error message
            classNames="w-full"
          />
        );

      default:
        break;
    }
  };

  const initializeComponent = async () => {
    try {
      const allowedCards = getAllowedWidgetsCards();

      const layoutWithCards = allowedCards.reduce(
        // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
        (acc, curr, i) => {
          acc.lg.push({ ...DEFAULT_LAYOUT.lg[i], ...DEFAUL_LAYOUT_CONSTANTS, i: curr });
          acc.md.push({ ...DEFAULT_LAYOUT.md[i], ...DEFAUL_LAYOUT_CONSTANTS, i: curr });
          acc.sm.push({ ...DEFAULT_LAYOUT.sm[i], ...DEFAUL_LAYOUT_CONSTANTS, i: curr });

          return acc;
        },
        { lg: [], md: [], sm: [] }
      );

      const res = await API.graphql(graphqlOperation(getDashboardSettings, { id: activeUser.id }));
      const settings = JSON.parse((res as any)?.data?.getUser?.dashboardSettings);
      const dashboardSettings = (settings && settings.layouts) || layoutWithCards;

      setState({
        layouts: dashboardSettings,
        // @ts-expect-error TS(7006): Parameter 'widget' implicitly has an 'any' type.
        items: allowedCards.map(widget => <div key={widget}>{getCardWidget(widget)}</div>),
      });

      setLoding(false);
    } catch (error) {
      console.error('error', error);
    }
  };

  useMountEffect(() => {
    initializeComponent();
  });

  const updateDashboard = async (updatedSettings: any) => {
    updatedSettings.items = updatedSettings.items.map((item: any) => item.key);
    const params = { id: activeUser.id, dashboardSettings: JSON.stringify(updatedSettings) };

    await API.graphql(graphqlOperation(updateUser, { input: params }));
  };

  const onLayoutChange = async (layout: any, layouts: any) => {
    await updateDashboard({ layouts, items: (state as any).items });
    setState({ layouts, items: (state as any).items });
  };

  if (loading) {
    return null;
  }

  return (
    <div className="page-container">
      <div>
        <ResponsiveReactGridLayout
          className="layout"
          breakpoints={BREAKPOINTS}
          cols={COLS}
          rowHeight={ROW_HEIGHT}
          layouts={(state as any).layouts}
          onLayoutChange={(layout: any, layouts: any) => onLayoutChange(layout, layouts)}
        >
          {(state as any).items}
        </ResponsiveReactGridLayout>
      </div>
    </div>
  );
};

export default DashboardLayout;
